<template>
  <app-dropdown-menu data-cy-user-entry :items="items">
    <template v-if="me" #prepend>
      <div class="flex items-center gap-4 p-1">
        <app-user-avatar
          :avatar-url="me.avatarUrl"
          class="!h-12 !w-12"
          :name="me.full_name"
          :size="96"
        />

        <div>
          <p class="font-semibold">
            {{ me.full_name }}
          </p>
          <p class="text-xs text-subtle">
            {{ me.email }}
          </p>
        </div>
      </div>
    </template>

    <template #default="{ open }">
      <div class="flex items-center gap-4 rounded bg-white px-4 py-2">
        <app-image
          :alt="organization.name"
          class="max-w-[100px] object-contain"
          :height="33"
          :src="organization.logo_url"
        />
        <app-icon
          class="transition"
          :class="{ 'rotate-180': open }"
          icon="ph:caret-down"
          :size="20"
        />
      </div>
    </template>
  </app-dropdown-menu>
</template>

<script lang="ts" setup>
import type { DropdownMenuItemDefinition } from "@pollen/ui-library/client/shared/dropdown/dropdown.model";

defineOptions({
  inheritAttrs: false,
});

defineProps<{
  organization: DatabaseTable<"organizations">;
}>();

const { logout, me } = useCurrentUser();
const { t } = useI18n();

const items: DropdownMenuItemDefinition[] = [
  {
    label: t("authentication.logout_label"),
    appendIcon: "ph:sign-out",
    id: "logout",
    listeners: {
      click: logout,
    },
  },
];
</script>
