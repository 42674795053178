<template>
  <app-button
    class="!bg-white !text-primary !shadow-none"
    @click="toggleDialog(true)"
  >
    {{ $t("support.default_cta_label") }}
  </app-button>

  <lazy-app-dialog
    v-if="showDialog"
    v-model="showDialog"
    icon="ph:question-duotone"
    :subtitle="$t('support.dialog.subtitle')"
    :title="$t('support.dialog.title')"
    :width="850"
  >
    <p class="mb-3 text-sm font-semibold">
      {{ $t("support.dialog.contact_label") }}
    </p>

    <support-contact-overview />

    <template #footer>
      <div class="grid place-content-end">
        <app-button :href="callScheduleUrl" size="lg">
          {{ $t("support.dialog.call_schedule_label") }}
        </app-button>
      </div>
    </template>
  </lazy-app-dialog>
</template>

<script lang="ts" setup>
const [showDialog, toggleDialog] = useToggle();

const callScheduleUrl = useRuntimeConfig().public.hubspot
  .callScheduleUrl as string;
</script>
