<template>
  <nav
    class="relative"
    :class="{
      'text-white/70': color === 'dark',
      'text-subtle': color === 'light',
    }"
    :style="{ '--itemHeight': `${itemHeight}px` }"
  >
    <div
      v-show="activeIndex >= 0"
      class="absolute w-full h-[--itemHeight] left-0 translate-y-[--top] top-0 rounded transition-transform"
      :class="{
        'bg-primary-950/50': color === 'dark',
        'bg-primary-100': color === 'light',
      }"
      :style="{ '--top': `${activeIndex * 100}%` }"
    />

    <ul class="relative">
      <li
        v-for="(item, index) in items"
        :key="item.label"
        v-tooltip="
          expanded
            ? undefined
            : {
                value: item.label,
                placement: 'right',
              }
        "
      >
        <nuxt-link
          class="grid grid-cols-[var(--itemHeight)_1fr] transition-all font-semibold"
          :class="{
            'hover:text-white': color === 'dark',
            'hover:text-primary': color === 'light',
            'text-white': activeIndex === index && color === 'dark',
            'text-primary': activeIndex === index && color === 'light',
            '!grid-cols-[var(--itemHeight)_0fr]': !expanded,
          }"
          :href="item.href"
          :target="item.href ? '_blank' : undefined"
          :to="item.to"
        >
          <span
            class="w-[--itemHeight] h-[--itemHeight] grid place-content-center"
          >
            <app-icon :icon="item.icon" :size="expanded ? 20 : 24" />
          </span>

          <span class="grid overflow-hidden">
            <span class="flex items-center justify-between pr-4">
              {{ item.label }}
              <app-icon
                v-if="item.appendIcon"
                :icon="item.appendIcon"
                :size="16"
              />
            </span>
          </span>
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import type { NavigationMenuItem } from "../navigation.model";

const expanded = defineModel("expanded", { default: true });

const properties = withDefaults(
  defineProps<{
    items: NavigationMenuItem[];
    color?: "light" | "dark";
    itemHeight?: number;
  }>(),
  {
    color: "dark",
    itemHeight: 48,
  },
);

const router = useRouter();
const activeIndex = ref(-1);

watch(
  () => useRoute().name,
  (currentRouteName) => {
    activeIndex.value = properties.items.findIndex((item) => {
      if (!item.to) return;

      const itemRouteName = router.resolve(item.to).name;
      const isExactMatch = itemRouteName === currentRouteName;
      const isParentMatch =
        itemRouteName &&
        currentRouteName?.toString().startsWith(itemRouteName.toString());

      return isExactMatch || isParentMatch;
    });
  },
  { immediate: true },
);
</script>
